import React, { useEffect, useState } from 'react';
import {
    Monitor,
    User,
    Upload,
    Calendar,
    Share2,
    Settings,
    Tool,
    Database,
    Zap,
    ZapOff,
    Sliders,
    Clipboard,
} from 'react-feather';
import { ROLES } from './roles';

export const LINKS = [
    {
        type: 'single',
        link: '/',
        label: 'Dashboard',
        Icon: Monitor,
        allowedRoles: ROLES.ALL,
        allowedFeatures: [],
    },
    {
        type: 'single',
        link: '/aclRegistration',
        label: 'ACL Registration',
        Icon: User,
        allowedRoles: ['CL', 'Developer'],
        allowedFeatures: [],
    },
    {
        type: 'single',
        link: '/committee/event',
        label: 'Events',
        Icon: Zap,
        allowedRoles: ['HOD','Head','Sub Head','Scorer','Volunteer','Admin Head','Super User','CL','Developer','ACL','NCP Representative'],
        allowedFeatures: [],
    },
    {
        type: 'single',
        label: 'Non Events',
        link: '/committee/non-event',
        Icon: ZapOff,
        allowedRoles: ['HOD','Head','Sub Head','Scorer','Volunteer','Admin Head','Super User','Developer'],
        allowedFeatures: [],
    },
    {
        type: 'single',
        label: 'Schedule',
        link: '/addSchedule',
        Icon: Calendar,
        allowedRoles: ['HOD','Head','Sub Head','Scorer','Volunteer','Admin Head','Super User','Developer','CL'],
        allowedFeatures: [],
    },
    {
        type : 'single',
        label : 'Scoreboard',
        link : '/scoreboard',
        Icon : Clipboard,
        allowedRoles : ['Super User','Developer','Scorer','Volunteer','HOD','Admin Head','Head','Sub Head'],
        allowedFeatures : [],
    },
    {
        type: 'single',
        link: '/collegesRequest',
        label: 'Colleges',
        Icon: Share2,
        allowedRoles: ['HOD','Head','Sub Head','Scorer','Volunteer','Admin Head','Super User','Developer',],
        allowedFeatures: [],
    },
    {
        type: 'group',
        heading: 'Data Banks',
        align: 'right',
        Icon: Database,
        links: [
            {
                label: 'Human Resource',
                link: '/allRegistration',
                allowedRoles: ['Super User', 'Developer', 'Admin Head', 'HOD'],
                allowedFeatures: [],
            },
            {
                label : 'Printable',
                link : '/printable',
                allowedRoles: ['Super User', 'Developer', 'HOD','Admin Head'],
                allowedFeatures: [],
            },
            {
                label : 'Website Creatives',
                link : '/websitesCreatives',
                allowedRoles : ['Super User','Developer','HOD', 'Admin Head'],
                allowedFeatures : [],
            }
        ],
    },
    {
        type: 'group',
        heading: 'Control Panel',
        align: 'right',
        Icon: Settings,
        links: [
            {
                label: 'Features',
                link: '/features/view',
                allowedRoles: ['HOD', 'Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label : 'Change Password',
                link: '/users/view-all-employee',
                allowedRoles: ['Super User', 'Developer', 'HOD', 'Admin Head'],
                allowedFeatures: [],
            },
            {
                label : 'Scorecard Adjustment',
                link : '/viewScorecard',
                allowedRoles: ['Super User','Developer'],
                allowedFeatures: [],
            },
        ],
    },
    {
        type: 'group',
        heading: 'Initial Setup',
        align: 'right',
        Icon: Sliders,
        links: [
            {
                label: 'Add Super User',
                link: '/users/add-employee',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View Super User',
                link: '/users/view-employee',
                allowedRoles: ['Super User','Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Add College',
                link: '/college/add',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View College',
                link: '/college/view',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Add Committee',
                link: '/committee/add',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View Committee',
                link: '/committee/view',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Add Event',
                link: '/event/add',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View Event',
                link: '/event/view',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View Scoring',
                link: '/scoringTemplate/view',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label : 'Add Years',
                link : '/addYear',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
            {
                label : 'Add Course',
                link : '/addCourse',
                allowedRoles: ['Super User', 'Developer'],
                allowedFeatures: [],
            },
        ],
    },
    {
        type: 'group',
        heading: 'Developer',
        align: 'right',
        Icon: Tool,
        links: [
            {
                label: 'Add Features',
                link: '/features/add',
                allowedRoles: ['Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Add Role',
                link: '/role/add',
                allowedRoles: ['Developer'],
                allowedFeatures: [],
            },
            {
                label: 'View Roles',
                link: '/role/view',
                allowedRoles: ['Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Add Scoring',
                link: '/scoringTemplate/add',
                allowedRoles: ['Developer'],
                allowedFeatures: [],
            },
            {
                label: 'Media Library',
                link: '/mediaLibrary',
                allowedRoles: ['Developer'],
                allowedFeatures: [],
            },
        ],
    },
    {
        type: 'single',
        link: '/generalBulkUpload',
        label: 'GeneralBulkUpload',
        Icon: Upload,
        allowedRoles: ['Developer', 'Super User'],
        allowedFeatures: [],
    },
    // {
    //     type: 'single',
    //     link: '/viewShortlistRequest',
    //     label: 'Registration Received',
    //     Icon: CheckSquare,
    //     allowedRoles: ['Admin Head', 'HOD'],
    //     allowedFeatures: ['65f148f6c9d73a599d816e59'],
    // },
    // {
    //     type : 'single',
    //     link : '/approveVolunteer',
    //     label : 'Committee Volunteer',
    //     Icon : PenTool,
    //     allowedRoles : ['Head', 'Sub Head','HOD'],
    //     allowedFeatures : [],
    // },
    // {
    //     type : 'single',
    //     link : '/commonPool',
    //     label : 'Common Pool',
    //     Icon : Briefcase,
    //     allowedRoles : ['Head', 'HOD', 'Admin Head'],
    //     allowedFeatures : []
    // },
    // {
    //     type : 'single',
    //     link : '/coreApproval',
    //     label : 'Core Approval',
    //     Icon : Layers,
    //     allowedRoles : [],
    //     allowedFeatures : []
    // },
    // {
    //     type: 'single',
    //     link: '/viewRegistrationRequest',
    //     label: 'Data Bank',
    //     Icon: Edit,
    //     allowedRoles: ['HOD','Head', 'Admin Head'],
    //     allowedFeatures: ['65f2a4b68cdb59418599add3'],
    // },
    // {
    //     type: 'group',
    //     heading: 'Users',
    //     align: 'right',
    //     Icon: User,
    //     links: [
    //         {
    //             label: 'Add Volunteer',
    //             link: '/users/add-employee',
    //             allowedRoles: [ 'Super User'],
    //             allowedFeatures : []

    //         },
    //         {
    //             label: 'View Volunteer',
    //             link: '/users/view-employee',
    //             allowedRoles: [ 'Super User'],
    //             allowedFeatures: [],
    //         },
    //     ],
    // },
    // {
    //     type: 'group',
    //     heading: 'College',
    //     align: 'right',
    //     Icon: BookOpen,
    //     links: [
    //         {
    //             label: 'Add College',
    //             link: '/college/add',
    //             allowedRoles: ['Super User'],
    //             allowedFeatures: [],
    //         },
    //         {
    //             label: 'View College',
    //             link: '/college/view',
    //             allowedRoles: ['Super User'],
    //             allowedFeatures: [],
    //         },
    //     ],
    // },
    // {
    //     type: 'group',
    //     heading: 'Role',
    //     align: 'right',
    //     Icon: Tag,
    //     links: [
    //         {
    //             label: 'Add Role',
    //             link: '/role/add',
    //             allowedRoles: ['Super User'],
    //             allowedFeatures: [],
    //         },
    //         {
    //             label: 'View Roles',
    //             link: '/role/view',
    //             allowedRoles: ['Super User'],
    //             allowedFeatures: [],
    //         },
    //     ],
    // },
    //   {
    //     type: "group",
    //     heading: "Festival",
    //     align: "right",
    //     Icon: Star,
    //     links: [
    //       {
    //         label: "Add Festival",
    //         link: "/festival/add",
    //         allowedRoles: ["Super User"],
    //         allowedFeatures : [],
    //       },
    //       {
    //         label: "View Festival",
    //         link: "/festival/view",
    //         allowedRoles: ["Super User"],
    //         allowedFeatures : [],
    //       },
    //     ],
    //   },
    // {
    //     type: 'group',
    //     heading: 'Committee',
    //     align: 'right',
    //     Icon: Slack,
    //     links: [
    //         {
    //             label: 'Add Committee',
    //             link: '/committee/add',
    //             allowedRoles: ['Super User','HOD'],
    //             allowedFeatures: [],
    //         },
    //         {
    //             label: 'View Committee',
    //             link: '/committee/view',
    //             allowedRoles: ['Super User','HOD'],
    //             allowedFeatures: [],
    //         },
    //         // {
    //         //     label : 'Event',
    //         //     link : '/committee/event',
    //         //     allowedRoles :['Super User','HOD'],
    //         //     allowedFeatures : []
    //         // },
    //         // {
    //         //     label : 'Non Event',
    //         //     link : '/committee/non-event',
    //         //     allowedRoles :['Super User','HOD'],
    //         //     allowedFeatures : []
    //         // }
    //     ],
    // },
    // {
    //     type : 'group',
    //     heading : 'Event',
    //     align : 'right',
    //     Icon : Calendar,
    //     links : [
    //         {
    //             label : 'Add Event',
    //             link : '/event/add',
    //             allowedRoles:['HOD', 'Super User'],
    //             allowedFeatures : []
    //         },
    //         {
    //             label : 'View Event',
    //             link : '/event/view',
    //             allowedRoles : ['HOD', 'Super User'],
    //             allowedFeatures : []
    //         }
    //     ]
    // },
    // {
    //     type : 'group',
    //     heading : 'Scoring Template',
    //     align : 'right',
    //     Icon : Activity,
    //     links : [
    //         {
    //             label : 'Add Scoring',
    //             link : '/scoringTemplate/add',
    //             allowedRoles : ['HOD','Super User'],
    //             allowedFeatures : []
    //         },
    //         {
    //             label : 'View Scoring',
    //             link : '/scoringTemplate/view',
    //             allowedRoles : ['HOD','Super User'],
    //             allowedFeatures : []
    //         }
    //     ]
    // },
    // {
    //     type: 'group',
    //     heading: 'Features',
    //     align: 'right',
    //     Icon: Lock,
    //     links: [
    //         {
    //             label: 'Add Features',
    //             link: '/features/add',
    //             allowedRoles: ['HOD', 'Super User'],
    //             allowedFeatures: [],
    //         },
    //         {
    //             label: 'View Features',
    //             link: '/features/view',
    //             allowedRoles: ['HOD', 'Super User'],
    //             allowedFeatures: [],
    //         },
    //     ],
    // },
    // {
    //     type: 'single',
    //     link: '/mediaLibrary',
    //     label: 'Media Library',
    //     Icon: Film,
    //     allowedRoles: ROLES.ALL,
    //     allowedFeatures: [],
    // },
];
