import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    editCollegeParticipant,
    fetchCollegeParticipants,
    getCollegeParticipant,
} from '../../app/reducers/CollegeParticipant/collegeParticipantSlice';
import {
    editEmployee,
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { fetchCollections } from '../../app/reducers/GeneralBulkUpload/generalBulkUploadSlice';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import {
    ACL_ROLE_ID,
    CL_ROLE_ID,
    NCP_REPRESENTATIVE_ROLE,
    UNAPPROVED_CL_ROLE_ID,
} from '../../utils/constants';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import ViewDetails from './ViewDetails';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import TableWithHeading from '../../components/Table/TableWithHeading';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import ViewClAclDetails from './ViewClAclDetails';
import { Link } from 'react-router-dom';
import { ChevronRight, Download } from 'react-feather';
import { actions } from 'react-table';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    fetchFeatures,
    getFeatures,
} from '../../app/reducers/Features/featuresSlice';
import { original } from '@reduxjs/toolkit';
import { ClipLoader } from 'react-spinners';
import { exportCsv } from '../../utils/Utils';
import {
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import ViewDetailsOfParticipants from '../Participant/ViewDetailsOfParticipants';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';

const CollegesRequest = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { features } = useSelector(getFeatures);
    const { collegeParticipant } = useSelector(getCollegeParticipant);
    // console.log('clg', collegeParticipant);
    const { teamParticipant } = useSelector(getParticipants);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [showNcpModal, setShowNcpModal] = useState(false);
    const [canShowApprove, setCanShowApprove] = useState(true);
    const [selectedTabindex, setSelectedTabindex] = useState(0);
    const [requestData, setRequestData] = useState({});
    const [clData, setClData] = useState({});
    const [aclData, setAclData] = useState([]);
    const [page, setPage] = useState(1);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isNcpDownloading, setIsNcpDownloading] = useState(false);

    const dispatch = useDispatch();

    const actionsBtn = ['Approve'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            Approve: setCanShowApprove,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures?.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            if (selectedTabindex === 0) {
                dispatch(
                    fetchCollegeParticipants({
                        search:search,
                        populate: true,
                        status: 'approved',
                        sort: { collegeCodeString: 1 },
                    })
                );
            }
            if (selectedTabindex === 1) {
                dispatch(
                    fetchCollegeParticipants({
                        search:search,
                        populate: true,
                        status: 'pending',
                        sort: { collegeCodeString: 1 },
                    })
                );
            }
            if (selectedTabindex === 2) {
                dispatch(
                    fetchCollegeParticipants({
                        search:search,
                        populate: true,
                        status: 'rejected',
                        sort: { collegeCodeString: 1 },
                    })
                );
            }
            if (selectedTabindex === 3) {
                dispatch(
                    fetchParticipants({
                        search:search,
                        populate: true,
                        status: 'approved',
                        type: 'ncp',
                        sort: { collegeCode: 1 },
                    })
                );
            }
        }, 300),
        [selectedTabindex]
    );
    useEffect(() => {
        if (selectedTabindex === 0) {
            dispatch(
                fetchCollegeParticipants({
                    populate: true,
                    status: 'approved',
                    sort: { collegeCodeString: 1 },
                    limit: 500,
                    page,
                })
            );
        }
        if (selectedTabindex === 1) {
            dispatch(
                fetchCollegeParticipants({
                    populate: true,
                    status: 'pending',
                    sort: { collegeCodeString: 1 },
                    limit: 500,
                    page,
                })
            );
        }
        if (selectedTabindex === 2) {
            dispatch(
                fetchCollegeParticipants({
                    populate: true,
                    status: 'rejected',
                    sort: { collegeCodeString: 1 },
                    limit: 500,
                    page,
                })
            );
        }
        if (selectedTabindex === 3) {
            dispatch(
                fetchParticipants({
                    populate: true,
                    status: 'approved',
                    type: 'ncp',
                    sort: { collegeCode: 1 },
                    limit: 500,
                    page,
                })
            );
        }
    }, [page, selectedTabindex]);
    useEffect(() => {
        dispatch(fetchFeatures());
    }, []);

    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    const pendingCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCodeString',
        },
        {
            Header: 'College',
            Cell: ({ row }) => {
                return <span>{row?.original?.college?.name}</span>;
            },
        },
        {
            Header: 'View Detail',
            Cell: ({ row }) => {
                return (
                    <div>
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                // console.log(
                                //     'pending details row data',
                                //     row?.original
                                // );
                                const payload = {
                                    username: row?.original?.collegeCodeString,
                                };
                                const string = QueryString.stringify(payload);
                                const userData = await authAxiosInstance.get(
                                    `/users/employee?${string}`
                                );
                                const singleUser =
                                    userData?.data?.data?.docs[0];
                                console.log('single user', singleUser);
                                setRequestData(singleUser);
                                setShowApprovedModal(true);
                            }}
                        >
                            View Details
                        </PrimaryButton>
                    </div>
                );
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2">
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) &&
                            canShowApprove && (
                                <PrimaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        // console.log('row', row?.original)
                                        const payload = {
                                            role: UNAPPROVED_CL_ROLE_ID,
                                            college:
                                                row?.original?.college?._id,
                                        };
                                        const string =
                                            QueryString.stringify(payload);
                                        const userData =
                                            await authAxiosInstance.get(
                                                `/users/employee?${string}`
                                            );
                                        const singleUser =
                                            userData?.data?.data?.docs[0];
                                        await dispatch(
                                            editCollegeParticipant({
                                                _id: row?.original?._id,
                                                status: 'approved',
                                            })
                                        );
                                        await dispatch(
                                            editEmployee({
                                                ...singleUser,
                                                role: CL_ROLE_ID,
                                                notHashPassword: true,
                                                status: true,
                                            })
                                        );
                                        await dispatch(
                                            fetchCollegeParticipants({
                                                populate: true,
                                                status: 'pending',
                                                sort: { collegeCodeString: 1 },
                                            })
                                        );
                                    }}
                                >
                                    Approve
                                </PrimaryButton>
                            )}
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const confirmReject = window.confirm(
                                        'Are you sure you want to reject this request ?'
                                        //  +
                                        //     '\n' +
                                        //     'Once rejected it will not going to trash again...'
                                    );
                                    if (confirmReject) {
                                        const payload = {
                                            role: UNAPPROVED_CL_ROLE_ID,
                                            college:
                                                row?.original?.college?._id,
                                        };
                                        const string =
                                            QueryString.stringify(payload);
                                        const userData =
                                            await authAxiosInstance.get(
                                                `/users/employee?${string}`
                                            );
                                        const singleUser =
                                            userData?.data?.data?.docs[0];
                                        await dispatch(
                                            editEmployee({
                                                ...singleUser,
                                                status: false,
                                            })
                                        );
                                        await dispatch(
                                            editCollegeParticipant({
                                                _id: row?.original?._id,
                                                status: 'rejected',
                                            })
                                        );
                                        await dispatch(
                                            fetchCollegeParticipants({
                                                populate: true,
                                                status: 'pending',
                                                sort: { collegeCodeString: 1 },
                                            })
                                        );
                                    }
                                }}
                            >
                                Reject
                            </DangerButton>
                        )}
                    </div>
                );
            },
        },
    ];
    const approvedColumns = [
        {
            Header: 'CC Code',
            accessor: 'collegeCodeString',
        },
        {
            Header: 'College',
            Cell: ({ row }) => {
                return <span>{row?.original?.college?.name}</span>;
            },
        },
        // {
        //     Header: 'Status',
        //     accessor: 'status',
        // },
        {
            Header: 'View Details',
            Cell: ({ row }) => {
                // console.log('row', row?.original)
                return (
                    <div>
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                // console.log('row ori', row?.original);
                                //Cl payload
                                const payload = {
                                    username: row?.original?.collegeCodeString,
                                };
                                const string = QueryString.stringify(payload);
                                const userData = await authAxiosInstance.get(
                                    `/users/employee?${string}`
                                );
                                const singleUser =
                                    userData?.data?.data?.docs[0];

                                // //Acl payload
                                const aclPayload = {
                                    role: ACL_ROLE_ID,
                                    college: row?.original?.college?._id,
                                };
                                console.log('payload', aclPayload);
                                const aclString =
                                    QueryString.stringify(aclPayload);
                                const resp = await authAxiosInstance.get(
                                    `/users/employee?${aclString}`
                                );
                                const aclRespData = resp?.data?.data?.docs;
                                console.log('acl data', aclRespData);
                                setClData(singleUser);
                                setAclData(aclRespData);
                                setShowApprovedModal(true);
                            }}
                        >
                            View Details
                        </PrimaryButton>
                    </div>
                );
            },
        },
        // {
        //     Header : ' ',
        //     Cell : ({row})=>{
        //         return(
        //             <div className='flex flex-row gap-2 items-center'>
        //                 <DangerButton
        //                     onClick={async(e)=>{
        //                         e.stopPropagation();
        //                         console.log('row', row?.original)
        //                         const payload = {
        //                             role: CL_ROLE_ID,
        //                             college: row?.original?.college?._id,
        //                         };
        //                         const string =
        //                             QueryString.stringify(payload);
        //                         const userData =
        //                             await authAxiosInstance.get(
        //                                 `/users/employee?${string}`
        //                             );
        //                         const singleUser =
        //                             userData?.data?.data?.docs[0];
        //                         await dispatch(
        //                             editCollegeParticipant({
        //                                 _id: row?.original?._id,
        //                                 status: 'pending',
        //                             })
        //                         );
        //                         await dispatch(
        //                             editEmployee({
        //                                 ...singleUser,
        //                                 role: UNAPPROVED_CL_ROLE_ID,
        //                                 notHashPassword: true,
        //                                 status : true
        //                             })
        //                         );
        //                         await dispatch(
        //                             fetchCollegeParticipants({
        //                                 populate: true,
        //                                 status: 'approved',
        //                                 sort: { collegeCodeString: 1 },
        //                             })
        //                         );
        //                     }}
        //                 >
        //                     Back To Pending
        //                 </DangerButton>
        //             </div>
        //         )
        //     }
        // },
    ];
    const rejectedCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCodeString',
        },
        {
            Header: 'College',
            Cell: ({ row }) => {
                return <span>{row?.original?.college?.name}</span>;
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const payload = {
                                        role: UNAPPROVED_CL_ROLE_ID,
                                        college: row?.original?.college?._id,
                                    };
                                    const string =
                                        QueryString.stringify(payload);
                                    const userData =
                                        await authAxiosInstance.get(
                                            `/users/employee?${string}`
                                        );
                                    const singleUser =
                                        userData?.data?.data?.docs[0];
                                    await dispatch(
                                        editEmployee({
                                            ...singleUser,
                                            status: true,
                                        })
                                    );
                                    await dispatch(
                                        editCollegeParticipant({
                                            _id: row?.original?._id,
                                            status: 'pending',
                                        })
                                    );
                                    dispatch(
                                        fetchCollegeParticipants({
                                            populate: true,
                                            status: 'rejected',
                                            sort: { collegeCodeString: 1 },
                                        })
                                    );
                                }}
                            >
                                Back To Pending
                            </DangerButton>
                        )}
                    </div>
                );
            },
        },
    ];
    const ncpCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCode',
        },
        {
            Header: 'College',
            accessor: 'ncpCollege',
        },
        {
            Header: 'View Details',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        <PrimaryButton
                            type="button"
                            onClick={async (e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowNcpModal(true);
                            }}
                        >
                            View Participants
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    const collegeDataMemo = useMemo(
        () => (collegeParticipant?.docs ? collegeParticipant?.docs : []),
        [collegeParticipant]
    );
    const approvedMemo = useMemo(
        () => (collegeParticipant?.docs ? collegeParticipant?.docs : []),
        [collegeParticipant]
    );
    const rejectedDataMemo = useMemo(
        () => (collegeParticipant?.docs ? collegeParticipant?.docs : []),
        [collegeParticipant]
    );

    const ncpMemo = useMemo(
        () => (teamParticipant?.docs ? teamParticipant?.docs : []),
        [teamParticipant]
    );

    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/collegesRequest'}>Colleges</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    {selectedTabindex === 0 && <span>{'Approved'}</span>}
                    {selectedTabindex === 1 && <span>{'Pending'}</span>}
                    {selectedTabindex === 2 && <span>{'Rejected'}</span>}
                </>
            }
        >
            {selectedTabindex === 0 && (
                <ViewClAclDetails
                    clData={clData}
                    aclData={aclData}
                    showApprovedModal={showApprovedModal}
                    setShowApprovedModal={setShowApprovedModal}
                />
            )}
            {selectedTabindex === 1 && (
                <ViewDetails
                    requestData={requestData}
                    showApprovedModal={showApprovedModal}
                    setShowApprovedModal={setShowApprovedModal}
                />
            )}
            {selectedTabindex === 3 && (
                <ViewDetailsOfParticipants
                    requestData={requestData}
                    showApprovedModal={showNcpModal}
                    setShowApprovedModal={setShowNcpModal}
                />
            )}
            <Tabs
                headers={['Approved', 'Pending', 'Rejected', 'NCP']}
                onTabChange={(index) => {
                    setSelectedTabindex(index);
                }}
            >
                <Tab>
                    <div className="mb-4">
                        {isDownloading ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    try {
                                        setIsDownloading(true);
                                        const clPayload = {
                                            role: {
                                                $in: [CL_ROLE_ID, ACL_ROLE_ID],
                                            },
                                        };
                                        const clString =
                                            QueryString.stringify(clPayload);
                                        const respData =
                                            await authAxiosInstance.get(
                                                `/users/employee?${clString}`
                                            );
                                        console.log(
                                            'clAcl',
                                            respData?.data?.data?.docs
                                        );
                                        const clAclArray =
                                            respData?.data?.data?.docs;
                                        const data = {
                                            populate: true,
                                            status: 'approved',
                                            limit: 5000,
                                            sort: { collegeCode: 1 },
                                        };
                                        const stringData =
                                            QueryString.stringify(data);
                                        const resp =
                                            await authAxiosInstance.get(
                                                `/collegeParticipant?${stringData}`
                                            );
                                        console.log(
                                            'resp',
                                            resp?.data?.data?.docs
                                        );
                                        const collegeList =
                                            resp?.data?.data?.docs;
                                        if (collegeList.length > 0) {
                                            let mainData = [];
                                            let srNo = 1;
                                            collegeList?.forEach(
                                                (ele, index) => {
                                                    const {
                                                        college,
                                                        collegeCodeString,
                                                        status,
                                                    } = ele;
                                                    clAclArray?.forEach(
                                                        (item) => {
                                                            if (
                                                                item.college ===
                                                                college?._id
                                                            ) {
                                                                mainData.push({
                                                                    srNo: srNo++,
                                                                    collegeCode:
                                                                        collegeCodeString,
                                                                    collegeName:
                                                                        college?.name,
                                                                    name: `${item.firstName} ${item.lastName}`,
                                                                    role:
                                                                        item.role ===
                                                                        CL_ROLE_ID
                                                                            ? 'CL'
                                                                            : item.role ===
                                                                                ACL_ROLE_ID
                                                                              ? 'ACL'
                                                                              : '',
                                                                    contactNumber:
                                                                        item.contactNumber,
                                                                    status: status,
                                                                });
                                                            }
                                                        }
                                                    );
                                                }
                                            );
                                            // console.log('mainData', mainData)
                                            const fileName = 'College List';
                                            exportCsv(mainData, fileName);
                                        }
                                    } catch (error) {
                                    } finally {
                                        setIsDownloading(false);
                                    }
                                }}
                            >
                                <Download size={14} className="mr-2" />
                                College List
                            </PrimaryButton>
                        )}
                    </div>
                    <TableWithHeadingAndGlobalSearch
                        columns={approvedColumns}
                        data={approvedMemo}
                        searchFunction={debouncedSearch}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={collegeParticipant}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndGlobalSearch
                        data={collegeDataMemo}
                        columns={pendingCols}
                        searchFunction={debouncedSearch}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={collegeParticipant}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndGlobalSearch
                        data={rejectedDataMemo}
                        columns={rejectedCols}
                        searchFunction={debouncedSearch}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={collegeParticipant}
                    />
                </Tab>
                <Tab>
                    <div className="mb-4">
                        {isNcpDownloading ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    try {
                                        setIsNcpDownloading(true);
                                        const ncpPayload = {
                                            role: NCP_REPRESENTATIVE_ROLE,
                                        };
                                        const ncpString =
                                            QueryString.stringify(ncpPayload);
                                        const respData =
                                            await authAxiosInstance.get(
                                                `/users/employee?${ncpString}`
                                            );
                                        // console.log('ncp reprent', respData?.data?.data?.docs)
                                        const ncpArray =
                                            respData?.data?.data?.docs;
                                        const data = {
                                            populate: true,
                                            status: 'approved',
                                            limit: 5000,
                                            type: 'ncp',
                                            sort: { collegeCode: 1 },
                                        };
                                        const stringData =
                                            QueryString.stringify(data);
                                        const resp =
                                            await authAxiosInstance.get(
                                                `/teamParticipant?${stringData}`
                                            );
                                        // console.log('resp', resp?.data?.data?.docs)
                                        const collegeList =
                                            resp?.data?.data?.docs;
                                        if (collegeList.length > 0) {
                                            let mainData = [];
                                            let srNo = 1;
                                            collegeList?.forEach(
                                                (ele, index) => {
                                                    const {
                                                        ncpCollege,
                                                        collegeCode,
                                                        status,
                                                        event,
                                                        participants,
                                                    } = ele;
                                                    ncpArray?.forEach(
                                                        (item) => {
                                                            if (
                                                                item.ncpCollege ===
                                                                ncpCollege
                                                            ) {
                                                                participants.forEach(
                                                                    (parti) => {
                                                                        mainData.push(
                                                                            {
                                                                                srNo: srNo++,
                                                                                collegeCode:
                                                                                    collegeCode,
                                                                                ncpCollegeName:
                                                                                    ncpCollege,
                                                                                eventName:
                                                                                    event?.name,
                                                                                representativeName: `${item.firstName} ${item.lastName}`,
                                                                                contactNumber:
                                                                                    item.contactNumber,
                                                                                // role : item.role === NCP_REPRESENTATIVE_ROLE ? 'NCP Representative' :'',
                                                                                participantName: `${parti.firstName} ${parti.lastName}`,
                                                                                participantContactNumber:
                                                                                    parti.contactNumber,
                                                                                status: status,
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    );
                                                }
                                            );
                                            // console.log('mainData', mainData)
                                            const fileName = 'NCP List';
                                            exportCsv(mainData, fileName);
                                        }
                                    } catch (error) {
                                    } finally {
                                        setIsNcpDownloading(false);
                                    }
                                }}
                            >
                                <Download size={14} className="mr-2" />
                                NCP List
                            </PrimaryButton>
                        )}
                    </div>
                    <TableWithHeadingAndGlobalSearch
                        data={ncpMemo}
                        columns={ncpCols}
                        searchFunction={debouncedSearch}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={collegeParticipant}
                    />
                </Tab>
            </Tabs>
            {/* <ShowDropdown
                allowedRoles={['HOD']}
                heading={
                    <SecondaryButton type="button" className="mb-4">
                        View Approved Participants
                    </SecondaryButton>
                }
            >
                
            </ShowDropdown> */}
        </PageWithCard>
    );
};

export default CollegesRequest;
