import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import SetButtons from '../../components/infrastructure/SetButtons';
import { Link, useParams } from 'react-router-dom';
import TableWithHeading from '../../components/Table/TableWithHeading';
import {
    fetchScoreBoards,
    fetchScoreBoardsWithCommittees,
    getScoreBoards,
} from '../../app/reducers/Scoreboard/scoreboardSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { Download } from 'react-feather';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { exportCsv } from '../../utils/Utils';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import {
    fetchWarOfClScores,
    getWarOfClScores,
} from '../../app/reducers/WarOfClScoring/warOfClScoringSlice';

const Scoreboard = () => {
    const { user } = useSelector(getAuth);
    const { page } = useParams();
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const { scoreBoard } = useSelector(getScoreBoards);
    const { warOfClScoring } = useSelector(getWarOfClScores);
    const { committee } = useSelector(getCommittees);
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useDispatch();
    const computeScoreData = (idArray) => {
        dispatch(
            fetchScoreBoardsWithCommittees({
                committeeIds: idArray,
                status: 'approved',
                populate: true,
            })
        );
    };
    const computeClScoreData = () => {
        dispatch(fetchWarOfClScores({ populate: true }));
    };
    useEffect(() => {
        dispatch(
            fetchCommittees({
                populate: true,
                type: 'Event',
                sort: { order: 1 },
            })
        );
    }, []);
    useEffect(() => {
        computeScoreData([]);
    }, [committee]);
    useEffect(() => {
        if (page) {
            const index = committeeData.findIndex((ele) => ele?.name === page);
            if (index != -1) {
                setActiveButtonIndex(index);
            }
        }
    }, []);

    const scoreboardCols = [
        {
            Header: 'CC Code',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color:
                                user?.username === row?.original?._id
                                    ? dynamicDefaultData?.highlightedText
                                    : '',
                        }}
                    >
                        {row?.original?._id}
                    </span>
                );
            },
        },
        {
            Header: 'Points',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color:
                                user?.username === row?.original?._id
                                    ? dynamicDefaultData?.highlightedText
                                    : '',
                        }}
                    >
                        {row?.original?.totalPoints}
                    </span>
                );
            },
        },
    ];
    const clScoreCols = [
        {
            Header: 'CC Code',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color:
                                user?.username === row?.original?._id
                                    ? dynamicDefaultData?.highlightedText
                                    : '',
                        }}
                    >
                        {row?.original?._id}
                    </span>
                );
            },
        },
        {
            Header: 'Win Count',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color:
                                user?.username === row?.original?._id
                                    ? dynamicDefaultData?.highlightedText
                                    : '',
                        }}
                    >
                        {row?.original?.winCount}
                    </span>
                );
            },
        },
    ];

    const committeeData = useMemo(() => {
        const data = committee?.docs ? committee?.docs : [];
        const restrictedRoles = ['CL', 'ACL', 'NCP Representative'];
        if (restrictedRoles.includes(user?.role?.name)) {
            return [{ name: 'Final', _id: 'final' }, ...data];
        } else {
            return [
                { name: 'Final', _id: 'final' },
                ...data,
                { name: 'War of CL', _id: 'warOfClId' },
            ];
        }
    }, [committee]);
    const scoreBoardData = useMemo(() => {
        let data = scoreBoard?.data ? [...scoreBoard?.data] : [];
        return data.sort((a, b) => a._id - b._id);
    }, [scoreBoard]);
    const clScoreData = useMemo(() => {
        const data = warOfClScoring?.docs ? [...warOfClScoring?.docs] : [];
        return data.sort((a, b) => b.winCount - a.winCount);
    }, [warOfClScoring]);;
    const handleButtonClick = (index, id) => {
        setActiveButtonIndex(index);
             if (id === 'warOfClId') {
            computeClScoreData();
        } else if (id === 'final') {
            computeScoreData([]);
        } else {
            computeScoreData([id]);
        }
    };

    return (
        <PageWithCard heading="Scoreboard">
            {['Super User', 'Developer', 'HOD', 'Admin Head'].includes(
                user?.role?.name
            ) &&
                activeButtonIndex === 0 && (
                    <div className="mb-4">
                        {isDownloading ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    try {
                                        setIsDownloading(true);
                                        let data = {
                                            committeeIds: [],
                                            status: 'approved',
                                            populate: true,
                                            limit: 5000,
                                        };
                                        // const string = QueryString.stringify(data);
                                        const resp =
                                            await authAxiosInstance.post(
                                                `/scoreBoard/getWithCommittees`,
                                                data
                                            );
                                       
                                        if (
                                            resp?.data?.data?.data?.length > 0
                                        ) {
                                            let mainData =
                                                resp?.data?.data?.data?.map(
                                                    (ele, index) => {
                                                        let {
                                                            _id,
                                                            totalPoints,
                                                            data,
                                                        } = ele;
                                                        let childData =
                                                            data?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    
                                                                    return {
                                                                        collegeName:
                                                                            item
                                                                                ?.college
                                                                                ?.name,
                                                                        eventName:
                                                                            item
                                                                                ?.event
                                                                                ?.name,
                                                                        notes: item?.notes,
                                                                        points: item?.points,
                                                                        collegeCode:
                                                                            item
                                                                                ?.team
                                                                                ?.collegeCode,
                                                                        mainCode:
                                                                            item
                                                                                ?.team
                                                                                ?.mainCode,
                                                                        rank: item?.rank,
                                                                        status: item?.status,
                                                                        type: item?.type,
                                                                    };
                                                                }
                                                            );
                                                        return childData;
                                                    }
                                                );

                                            mainData = mainData
                                                .flat()
                                                .map((item, index) => ({
                                                    serialNumber: index + 1,
                                                    ...item,
                                                }));
                                            const fileName = 'Scoreboard Data';
                                            exportCsv(mainData, fileName);
                                        }
                                    } catch (error) {
                                        console.log(error);
                                        toast.error('Fail to Download');
                                    } finally {
                                        setIsDownloading(false);
                                    }
                                }}
                            >
                                <Download size={16} className="mr-2" />{' '}
                                Scoreboard Data
                            </PrimaryButton>
                        )}
                    </div>
                )}
            <SetButtons>
                <div className="flex items-center justify-start divide-x divide-slate-600">
                    {committeeData?.map((ele, index) => (
                        <Link key={index} to={`/scoreboard`}>
                            <button
                                className={
                                    activeButtonIndex === index
                                        ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                        : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                }
                                onClick={() =>
                                    handleButtonClick(index, ele._id)
                                }
                            >
                                {ele?.name}
                            </button>
                        </Link>
                    ))}
                </div>
            </SetButtons>
            {!['CL', 'ACL', 'NCP Representative'].includes(user?.role?.name) ? (
                activeButtonIndex !== committeeData.length - 1 && (
                    <div className="w-full md:w-2/3">
                        <TableWithHeading
                            columns={scoreboardCols}
                            data={scoreBoardData}
                        />
                    </div>
                )
            ) : (
                <div className="w-full md:w-2/3">
                    <TableWithHeading
                        columns={scoreboardCols}
                        data={scoreBoardData}
                    />
                </div>
            )}
            {!['CL', 'ACL', 'NCP Representative'].includes(user?.role?.name)
                ? activeButtonIndex === committeeData.length - 1 && (
                      <div className="w-full md:w-2/3">
                          <TableWithHeading
                              columns={clScoreCols}
                              data={clScoreData}
                          />
                      </div>
                  )
                : !['CL', 'ACL', 'NCP Representative'].includes(
                      user?.role?.name
                  ) && (
                      <div className="w-full md:w-2/3">
                          <TableWithHeading
                              columns={clScoreCols}
                              data={clScoreData}
                          />
                      </div>
                  )}
        </PageWithCard>
    );
};

export default Scoreboard;
