import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    editRegistrationRequest,
    editShortlistRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
    resetRegistrationRequest,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import {
    editCommittee,
    fetchCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import {
    deleteEmployee,
    editEmployee,
    fetchEmployees,
    getEmployees,
    resetEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import PromoteVolunteer from './PromoteVolunteer';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { CORE_ROLE, VOLUNTEER_ROLE_ID } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { clearConfigCache } from 'prettier';
import { toast } from 'react-toastify';
import { ChevronRight, Eye } from 'react-feather';
import PendingReqPromote from '../AllRegistration/PendingReqPromote';
import ShortlistPromoteReqRole from './ShortlistPromoteReqRole';
import ShortlistReqPromote from '../AllRegistration/ShortlistReqPromote';
import ApprovedVolunteerShortlistReqPromote from './ApprovedVolunteerShortlistReqPromote';
import Preview from '../AllRegistration/Preview';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useFormik } from 'formik';
import { values } from 'regenerator-runtime';
import FormikLabel from '../../components/formik/FormikLabel';
import FormikInputGroup from '../../components/formik/FormikInputGroup';

const ApproveVolunteer = ({ actionFeatures }) => {
    const { id } = useParams();
    const { user } = useSelector(getAuth);
    console.log('user', user);
    const navigate = useNavigate();
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showShortlistPromoteModal, setShowShortlistPromoteModal] =
        useState(false);
    const [requestData, setRequestData] = useState({});
    const [shortlistRequestData, setShortlistRequestData] = useState({});
    const [showPromoteModal, setShowPromoteModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [currentUser, setCurrentUser] = useState({});
    const [canShowApprove, setCanShowApprove] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(1);
    const [reqPage, setReqPage] = useState(1);
    const { registrationRequest, loading } = useSelector(
        getRegistrationRequests
    );
    const { employees, loadingEmployees } = useSelector(getEmployees);
    // console.log('approve employees', employees?.docs);
    const { features } = useSelector(getFeatures);
    const { committee, elementEditData } = useSelector(getCommittees);
    const [scorerData, setScorerData] = useState({});
    console.log('committee data single', elementEditData);
    const dispatch = useDispatch();
    console.log('SelectedRow', selectedRows);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(
                fetchRegistrationRequests({
                    search: search,
                    'committee1._id': id,
                    populate: true,
                })
            );
        }, 300),
        []
    );

    const actionsBtn = ['ApproveDecline'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures.forEach((id, index) => {
            config[id] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            // 'Promote' : setCanShowPromote,
            // 'Reset' : setCanShowReset,
            ApproveDecline: setCanShowApprove,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures?.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        console.log('action', action);
                        const status = item?.status;
                        console.log('status', status);
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };
    const committeeNameData = committee?.docs?.find(
        (single) => single?._id === id
    );
    let ShortlistColumns = [
        // {
        //     id : 'Select All',
        //     Header: () => (
        //         <input
        //             type="checkbox"
        //             checked={selectAll}
        //             onChange={(e) => handleSelectAll(e.target.checked)}
        //         />
        //     ),
        //     Cell: ({ row }) => (
        //         <input
        //             type="checkbox"
        //             checked={selectedRows.includes(row?.original?._id)}
        //             onChange={(e) => {
        //                 console.log('event', e.currentTarget.checked);
        //                 if (e.currentTarget.checked) {
        //                     setSelectedRows([
        //                         ...selectedRows,
        //                         row?.original?._id,
        //                     ]);
        //                 } else {
        //                     const filtered = selectedRows.filter(
        //                         (ele) => ele !== row?.original?._id
        //                     );
        //                     setSelectedRows(filtered);
        //                 }
        //             }}
        //         />
        //     ),
        //     cellStyle: {
        //         width: '10%',
        //     },
        // },
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Year',
            accessor: 'year',
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Course',
            accessor: 'courses',
            cellStyle: {
                width: '15%',
            },
        },
        // {
        //     Header: 'Committee',
        //     accessor: 'committee1.name',
        //     cellStyle: {
        //         width: '20%',
        //     },
        // },
        // {
        //     Header: 'Status',
        //     Cell: ({ row }) => {
        //         return <span>{row?.original?.status}</span>;
        //     },
        //     cellStyle: {
        //         width: '20%'
        //     }
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2">
                        {([
                            'Head',
                            'Super User',
                            'Developer',
                            'HOD',
                            'Admin Head',
                        ].includes(user?.role?.name) ||
                            user?.isSuperUser) && (
                            <div className="flex flex-row gap-2">
                                {['pending', 'shortlisted'].includes(
                                    row?.original?.status
                                ) &&
                                    (( ['Admin Head','Head'].includes(user?.role?.name)  && user?.committee[0]?._id === id) ||
                                        [
                                            'HOD',
                                            'Super User',
                                            'Developer',
                                        ].includes(user?.role?.name)) &&
                                    canShowApprove && (
                                        <PrimaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                console.log(
                                                    'data row',
                                                    row?.original
                                                );

                                                let canSubmit = false;
                                                let errorCommittee = null;

                                                const roleOptions =
                                                    committee?.docs?.find(
                                                        (ele) =>
                                                            ele._id ===
                                                            row?.original
                                                                ?.committee1
                                                                ?._id
                                                    );
                                                console.log(
                                                    'role opt',
                                                    roleOptions
                                                );
                                                const seatAvailable =
                                                    roleOptions?.members?.find(
                                                        (ele) =>
                                                            ele.role?._id ===
                                                            VOLUNTEER_ROLE_ID
                                                    );
                                                console.log(
                                                    'seatAvailable',
                                                    seatAvailable
                                                );
                                                if (!seatAvailable?.seats) {
                                                    toast.error(
                                                        'Seats are not allocated'
                                                    );
                                                    return;
                                                }
                                                const payload = {
                                                    role: VOLUNTEER_ROLE_ID,
                                                    committee:
                                                        row?.original
                                                            ?.committee1?._id,
                                                };
                                                console.log('payload', payload);
                                                const string =
                                                    QueryString.stringify(
                                                        payload
                                                    );
                                                const allUsers =
                                                    await authAxiosInstance.get(
                                                        `/users/employee?${string}`
                                                    );
                                                const userGet =
                                                    allUsers?.data?.data?.docs;
                                                console.log('userGet', userGet);
                                                if (
                                                    seatAvailable?.seats >
                                                    userGet?.length
                                                ) {
                                                    canSubmit = true;
                                                    console.log(
                                                        'canSubmitTrue',
                                                        'true'
                                                    );
                                                } else {
                                                    canSubmit = false;
                                                    console.log(
                                                        'canSubmitFalse',
                                                        'false'
                                                    );
                                                    errorCommittee =
                                                        committee?.docs?.find(
                                                            (ele) =>
                                                                ele._id ===
                                                                row?.original
                                                                    .committee1
                                                                    ?._id
                                                        );
                                                    console.log(errorCommittee);
                                                    toast.error(
                                                        ` Seats are full for ${errorCommittee.name} committee`
                                                    );
                                                    return;
                                                }

                                                if (canSubmit) {
                                                    console.log(
                                                        'submit true condition'
                                                    );
                                                    const data = {
                                                        userId: row?.original
                                                            ?._id,
                                                        ...row?.original,
                                                        role: VOLUNTEER_ROLE_ID,
                                                        committee:
                                                            row?.original
                                                                ?.committee1
                                                                ?._id,
                                                        // preferredCommittee: `${row?.original?.committee1?._id},${row?.original?.committee2?._id},${row?.original?.committee3?._id}`,
                                                    };
                                                    console.log(
                                                        'dataObj',
                                                        data
                                                    );
                                                    await dispatch(
                                                        editRegistrationRequest(
                                                            data
                                                        )
                                                    );
                                                    if (id) {
                                                        await dispatch(
                                                            fetchRegistrationRequests(
                                                                {
                                                                    populate: true,
                                                                    status: 'shortlisted',
                                                                    'committee1._id':
                                                                        id,
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        await dispatch(
                                                            fetchRegistrationRequests(
                                                                {
                                                                    populate: true,
                                                                    status: 'shortlisted',
                                                                }
                                                            )
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            Approve
                                        </PrimaryButton>
                                    )}
                                {/* {
                                    ['approved', 'shortlisted'].includes(
                                        row?.original?.status
                                    ) &&
                                        ( user?.committee[0]?._id === id ||
                                            [
                                                'HOD',
                                                'Super User',
                                                'Developer',
                                            ].includes(user?.role?.name)) && (
                                                canShowApprove &&
                                            <DangerButton
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    console.log(row?.original);
                                                    await dispatch(
                                                        editShortlistRegistrationRequest(
                                                            {
                                                                ...row.original,
                                                                status: 'pending',
                                                            }
                                                        )
                                                    );
                                                    if (id) {
                                                        await dispatch(
                                                            fetchRegistrationRequests(
                                                                {
                                                                    populate: true,
                                                                    'committee1._id':
                                                                        id,
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        await dispatch(
                                                            fetchRegistrationRequests(
                                                                {
                                                                    populate: true,
                                                                }
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                Decline
                                            </DangerButton>
                                        )
                                    
                                } */}
                            </div>
                        )}
                        {/* {['HOD', 'Super User', 'Developer'].includes(
                            user?.role?.name
                        ) && ( 
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setShortlistRequestData(row?.original);
                                    setShowShortlistPromoteModal(true);
                                }}
                            >
                                Promote
                            </PrimaryButton>
                        )} */}
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) && (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPromoteModal(true);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        )}
                        {
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPreviewModal(true);
                                }}
                                color="#2563eb"
                            />
                            // Preview
                            // </PrimaryButton>
                        }
                    </div>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
    ];

    let ApproveColumns = [
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        // {
        //     Header: 'Committee',
        //     Cell: ({ row }) => {
        //         return (
        //             <ul>
        //                 {row?.original?.committee?.map((ele, i) => (
        //                     <li key={i}>{<span>{ele?.name}</span>}</li>
        //                 ))}
        //             </ul>
        //         );
        //     },
        //     cellStyle: {
        //         width: '25%',
        //     },
        // },
        {
            Header: 'Role',
            accessor: 'role.name',
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Year',
            accessor: 'year',
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Course',
            accessor: 'courses',
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    ([
                        'Admin Head',
                        'Super User',
                        'Developer',
                        'HOD',
                        'Head',
                        'Sub Head',
                        'Volunteer',
                    ].includes(user?.role?.name) ||
                        user?.isSuperUser) && (
                        <div className="flex flex-row gap-2">
                            {
                                <Eye
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRequestData(row?.original);
                                        setShowPreviewModal(true);
                                    }}
                                    color="#2563eb"
                                />
                                // Preview
                                // </PrimaryButton>
                            }
                            {([
                                'Admin Head',
                                'Super User',
                                'Developer',
                                'HOD',
                                'Head',
                            ].includes(user?.role?.name) ||
                                user?.isSuperUser) &&
                                 (
                                    (( ['Head','Admin Head'].includes(user?.role?.name) && user?.committee[0]?._id === id) ||
                                        [
                                            'HOD',
                                            'Super User',
                                            'Developer',
                                        ].includes(user?.role?.name)) &&
                                    // user?._id !== row?.original?._id &&
                                    // user?.role?._id !==
                                    //     row?.original?.role?._id &&
                                    !(
                                        CORE_ROLE.includes(user?.role?.name) ===
                                        CORE_ROLE.includes(
                                            row?.original?.role?.name
                                        )
                                    ) &&
                                     (canShowApprove  && (
                                        <DangerButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                console.log(
                                                    'decline btn',
                                                    row?.original
                                                );
                                                const restObj = {
                                                    committee: [],
                                                    role: '',
                                                    status: 'declined',
                                                    ...row.original,
                                                };
                                                console.log('reset', restObj);
                                                await dispatch(
                                                    editEmployee(restObj)
                                                );
                                                await dispatch(
                                                    deleteEmployee({
                                                        id: row?.original?._id,
                                                    })
                                                );
                                                if (id) {
                                                    dispatch(
                                                        fetchEmployees({
                                                            populate: true,
                                                            'committee._id': id,
                                                        })
                                                    );
                                                } else {
                                                    dispatch(
                                                        fetchEmployees({
                                                            populate: true,
                                                        })
                                                    );
                                                }
                                                const payload = {
                                                    username:
                                                        row?.original?.username,
                                                };
                                                const string =
                                                    QueryString.stringify(
                                                        payload
                                                    );
                                                console.log('payload', string);
                                                const registrationResp =
                                                    await authAxiosInstance.get(
                                                        `/registrationRequest?${string}`
                                                    );
                                                const registrationObj =
                                                    registrationResp?.data?.data
                                                        ?.docs[0];
                                                await dispatch(
                                                    editShortlistRegistrationRequest(
                                                        {
                                                            ...registrationObj,
                                                            status: 'shortlisted',
                                                        }
                                                    )
                                                );
                                                if (id) {
                                                    dispatch(
                                                        fetchRegistrationRequests(
                                                            {
                                                                populate: true,
                                                                'committee1._id':
                                                                    id,
                                                            }
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        fetchRegistrationRequests(
                                                            {
                                                                populate: true,
                                                            }
                                                        )
                                                    );
                                                }
                                            }}
                                        >
                                            Decline
                                        </DangerButton>)
                                    ))}
                            {/* {loadingEmployees ? (
                                <Clipboard />
                            ) : (
                                ['HOD', 'Super User', 'Developer'].includes(
                                    user?.role?.name
                                ) &&
                                (user?._id !== row?.original?._id &&  (
                                    <PrimaryButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            setRequestData(row?.original);
                                            setShowApprovedModal(true);
                                        }}
                                    >
                                        Promote
                                    </PrimaryButton>
                                ))
                            )} */}
                            {/* {loadingEmployees ? (
                                <Clipboard />
                            ) : (
                                ['HOD', 'Super User', 'Developer'].includes(
                                    user?.role?.name
                                ) &&
                                ((user?._id !== row?.original?._id && user?.role?._id !== row?.original?.role?._id) && (
                                    <DangerButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            console.log(
                                                'reset btn',
                                                row?.original
                                            );
                                            const restObj = {
                                                committee: [],
                                                role: '',
                                                status: 'pending',
                                                ...row.original,
                                            };
                                            console.log('reset', restObj);
                                            await dispatch(
                                                editEmployee(restObj)
                                            );
                                            await dispatch(
                                                deleteEmployee({
                                                    id: row?.original?._id,
                                                })
                                            );
                                            if (id) {
                                                dispatch(
                                                    fetchEmployees({
                                                        populate: true,
                                                        'committee._id': id,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    fetchEmployees({
                                                        populate: true,
                                                    })
                                                );
                                            }
                                            const payload = {
                                                username:
                                                    row?.original?.username,
                                            };
                                            const string =
                                                QueryString.stringify(payload);
                                            console.log('payload', string);
                                            const registrationResp =
                                                await authAxiosInstance.get(
                                                    `/registrationRequest?${string}`
                                                );
                                            const registrationObj =
                                                registrationResp?.data?.data
                                                    ?.docs[0];
                                            await dispatch(
                                                editShortlistRegistrationRequest(
                                                    {
                                                        ...registrationObj,
                                                        status: 'pending',
                                                    }
                                                )
                                            );
                                            if (id) {
                                                dispatch(
                                                    fetchRegistrationRequests({
                                                        populate: true,
                                                        'committee1._id': id,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    fetchRegistrationRequests({
                                                        populate: true,
                                                    })
                                                );
                                            }
                                        }}
                                    >
                                        Reset
                                    </DangerButton>
                                ))
                            )} */}
                        </div>
                    )
                );
            },
            cellStyle: {
                width: '25%',
            },
        },
    ];

    const dataMemo = useMemo(
        () =>
            (registrationRequest?.docs
                ? registrationRequest?.docs.filter(
                      (ele) => ele.status === 'shortlisted'
                  )
                : []),
        [registrationRequest]
    );

    const volunteerMemo = useMemo(
        () => (employees?.docs ? employees?.docs : []),
        [employees]
    );
    console.log('approved memo', volunteerMemo);
    const volunteerOptions = useMemo(() => {
        return volunteerMemo
            .filter((employee) => employee?.role?.name)
            .map((employee) => ({
                label: `${employee.firstName} ${employee.lastName}`,
                value: employee._id,
            }));
    }, [volunteerMemo]);

    const handleSelectAll = (checked) => {
        if (checked) {
            const allIds = dataMemo.map((row) => row._id);
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(checked);
    };

    const formik = useFormik({
        enableReinitialize : true,
        initialValues: {
            scorer: '',
        },
        onSubmit: async (values, { resetForm }) => {
            console.log('values', values);
            let obj = {
                _id: id,
                scorer: values?.scorer,
            };
            console.log('obj edit', obj);
            await dispatch(editCommittee(obj));
            await dispatch(fetchCommittee({ _id: id }));
            resetForm();
        },
    });

    useEffect(() => {
        if (selectedTabIndex === 0) {
            if (id) {
                dispatch(
                    fetchEmployees({
                        populate: true,
                        'committee._id': id,
                        sort: { 'role.name': 1, firstName: 1 },
                    })
                );
                dispatch(fetchCommittee({ _id: id }));
            } else {
                dispatch(fetchEmployees({ populate: true }));
            }
        }
        computeActiveFeature();
        // getUser();
        if (selectedTabIndex === 1) {
            if (id) {
                dispatch(
                    fetchRegistrationRequests({
                        'committee1._id': id,
                        status: 'shortlisted',
                        populate: true,
                    })
                );
            } else {
                dispatch(
                    fetchRegistrationRequests({
                        status: 'shortlisted',
                        populate: true,
                    })
                );
            }
        }
        return () => {
            dispatch(resetEmployees());
            dispatch(resetRegistrationRequest());
        };
    }, [selectedTabIndex, features]);

    useEffect(() => {
        if (elementEditData?.scorer) {
            const fetchScorerData = async () => {
                try {
                    const string = QueryString.stringify({
                        _id: elementEditData?.scorer,
                    });
                    const resp = await authAxiosInstance.get(
                        `/users/employee?${string}`
                    );
                    const data = resp?.data?.data?.docs[0];
                    // console.log('data', data);
                    setScorerData(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchScorerData();
        }
    }, [elementEditData]);
    // console.log('scorer chhe k nai', elementEditData?.scorer)
    return (
        <PageWithCard
            heading={
                id ? (
                    <>
                        {elementEditData?.type === 'Event' && (
                            <Link to={'/committee/event'}>Events</Link>
                        )}
                        {elementEditData?.type === 'Non Event' && (
                            <Link to={'/committee/non-event'}>Non Events</Link>
                        )}
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />{' '}
                        </span>
                        <span>{committeeNameData?.name}</span>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />{' '}
                        </span>
                        <span>{'Committee'}</span>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />{' '}
                        </span>
                        {selectedTabIndex === 0 && <span>{'Approved'}</span>}
                        {selectedTabIndex === 1 && <span>{'Shortlisted'}</span>}
                    </>
                ) : (
                    'Individual Committee Volunteer'
                )
            }
        >
            {selectedTabIndex === 0 && (
                <>
                    <PromoteVolunteer
                        requestData={requestData}
                        showApprovedModal={showApprovedModal}
                        setShowApprovedModal={setShowApprovedModal}
                    />
                    <Preview
                        requestData={requestData}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                    />
                </>
            )}
            {selectedTabIndex === 1 && (
                <>
                    <ShortlistPromoteReqRole
                        requestData={shortlistRequestData}
                        showApprovedModal={showShortlistPromoteModal}
                        setShowApprovedModal={setShowShortlistPromoteModal}
                    />
                    <ApprovedVolunteerShortlistReqPromote
                        requestData={requestData}
                        showApprovedModal={showPromoteModal}
                        setShowApprovedModal={setShowPromoteModal}
                    />
                    <Preview
                        requestData={requestData}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                    />
                </>
            )}

            <Tabs
                headers={['Approved', 'Shortlisted']}
                onTabChange={(index) => {
                    setSelectedTabIndex(index);
                }}
            >
                <Tab>
                    {elementEditData?.type === 'Event' && (
                        <div className="mb-2">
                            <ShowDropdownForSchedule
                                heading={
                                    <div className="mb-2">
                                        <h1 className="text-slate-200 text-2xl font-medium px-2">
                                            Scorer
                                        </h1>
                                    </div>
                                }
                                allowedRoles={[
                                    'HOD',
                                    'Super User',
                                    'Developer',
                                    'Head',
                                    'Volunteer',
                                    'Sub Head',
                                    'Admin Head',
                                ]}
                            >
                                {!elementEditData?.scorer ? (
                                    <form
                                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md md:w-1/2"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="Name" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikSelectGroup
                                                    name="scorer"
                                                    formik={formik}
                                                    options={volunteerOptions}
                                                />
                                            </div>
                                        </div>
                                        {[
                                            'HOD',
                                            'Super User',
                                            'Developer',
                                        ].includes(user?.role?.name) && (
                                            <div className="">
                                                <PrimaryButton type="submit">
                                                    Submit
                                                </PrimaryButton>
                                            </div>
                                        )}
                                    </form>
                                ) : (
                                    <form
                                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md md:w-1/2"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="flex flex-row items-center gap-4">
                                            <FormikInputGroup
                                                label="First Name"
                                                name="firstName"
                                                formik={formik}
                                                value={scorerData?.firstName || ''}
                                            />
                                            <FormikInputGroup
                                                label="Last Name"
                                                name="lastName"
                                                formik={formik}
                                                value={scorerData?.lastName || ''}
                                            />
                                            <FormikInputGroup
                                                label="Phone Number"
                                                name="contactNumber"
                                                formik={formik}
                                                value={scorerData?.contactNumber || ''}
                                            />
                                        </div>
                                        {
                                            <div className="">
                                                {(
                                                    [
                                                        'HOD',
                                                        'Super User',
                                                        'Developer',
                                                    ].includes(
                                                        user?.role?.name
                                                    ) || user?.isSuperUser) &&
                                                    canShowApprove && (
                                                        <DangerButton
                                                            onClick={async (
                                                                e
                                                            ) => {
                                                                e.stopPropagation();
                                                                let obj = {
                                                                    _id: id,
                                                                    scorer: '',
                                                                };
                                                                await dispatch(
                                                                    editCommittee(
                                                                        obj
                                                                    )
                                                                );
                                                                await dispatch(
                                                                    fetchCommittee(
                                                                        {
                                                                            _id: id,
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            Decline
                                                        </DangerButton>
                                                    )}
                                            </div>
                                        }
                                    </form>
                                )}
                            </ShowDropdownForSchedule>
                        </div>
                    )}
                    <TableWithHeadingAndSearch
                        data={volunteerMemo}
                        columns={ApproveColumns}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={employees}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndGlobalSearch
                        heading={
                            ([
                                'HOD',
                                'Head',
                                'Developer',
                                'Super User',
                            ].includes(user?.role?.name) ||
                                user?.isSuperUser) &&
                            (currentUser?.committee?.some(
                                (item) => item?._id === id
                            ) ||
                                (['HOD', 'Super User', 'Developer'].includes(
                                    user?.role?.name
                                ) &&
                                    selectedRows.length > 0 &&
                                    canShowApprove && (
                                        <PrimaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                let canSubmit = false;
                                                let errorCommittee = null;
                                                for (
                                                    let i = 0;
                                                    i < selectedRows.length;
                                                    i++
                                                ) {
                                                    const stringRegReq =
                                                        QueryString.stringify({
                                                            _id: selectedRows[
                                                                i
                                                            ],
                                                        });
                                                    const regUserData =
                                                        await authAxiosInstance.get(
                                                            `/registrationRequest?${stringRegReq}`
                                                        );
                                                    const selectedRowsUser =
                                                        regUserData?.data?.data
                                                            ?.docs[0];
                                                    console.log(
                                                        'search data',
                                                        selectedRowsUser
                                                    );
                                                    const roleOptions =
                                                        committee?.docs?.find(
                                                            (ele) =>
                                                                ele._id ===
                                                                selectedRowsUser?.committee1
                                                        );
                                                    console.log(
                                                        'role opt',
                                                        roleOptions
                                                    );
                                                    const seatAvailable =
                                                        roleOptions?.members?.find(
                                                            (ele) =>
                                                                ele.role ===
                                                                VOLUNTEER_ROLE_ID
                                                        );
                                                    console.log(
                                                        'seat available',
                                                        seatAvailable
                                                    );
                                                    const payload = {
                                                        role: VOLUNTEER_ROLE_ID,
                                                        committee:
                                                            selectedRowsUser?.committee1,
                                                    };

                                                    const string =
                                                        QueryString.stringify(
                                                            payload
                                                        );
                                                    const allUsers =
                                                        await authAxiosInstance.get(
                                                            `/users/employee?${string}`
                                                        );
                                                    const userGet =
                                                        allUsers?.data?.data
                                                            ?.docs;
                                                    console.log(
                                                        'userGet',
                                                        userGet
                                                    );
                                                    if (!seatAvailable?.seats) {
                                                        toast.error(
                                                            'Seats are not allocated'
                                                        );
                                                        return;
                                                    }

                                                    if (
                                                        seatAvailable?.seats >
                                                        userGet?.length
                                                    ) {
                                                        canSubmit = true;
                                                        console.log(
                                                            'canSubmitTrue',
                                                            'true'
                                                        );
                                                    } else {
                                                        canSubmit = false;
                                                        console.log(
                                                            'canSubmitFalse',
                                                            'false'
                                                        );
                                                        errorCommittee =
                                                            committee?.docs?.find(
                                                                (ele) =>
                                                                    ele._id ===
                                                                    selectedRowsUser.committee1
                                                            );
                                                        console.log(
                                                            errorCommittee
                                                        );
                                                        toast.error(
                                                            `${errorCommittee.name} seats are not available`
                                                        );
                                                        return;
                                                    }

                                                    if (canSubmit) {
                                                        const data = {
                                                            userId: selectedRowsUser?._id,
                                                            ...selectedRowsUser,
                                                            role: VOLUNTEER_ROLE_ID,
                                                            committee:
                                                                selectedRowsUser?.committee1,
                                                            // preferredCommittee: `${selectedRowsUser?.committee1?._id},${selectedRowsUser?.committee2?._id},${selectedRowsUser?.committee3?._id}`,
                                                        };
                                                        console.log(
                                                            'dataObj',
                                                            data
                                                        );
                                                        await dispatch(
                                                            editRegistrationRequest(
                                                                data
                                                            )
                                                        );
                                                        if (id) {
                                                            await dispatch(
                                                                fetchRegistrationRequests(
                                                                    {
                                                                        populate: true,
                                                                        status: 'shortlisted',
                                                                        'committee1._id':
                                                                            id,
                                                                    }
                                                                )
                                                            );
                                                        } else {
                                                            await dispatch(
                                                                fetchRegistrationRequests(
                                                                    {
                                                                        populate: true,
                                                                        status: 'shortlisted',
                                                                    }
                                                                )
                                                            );
                                                        }
                                                    }
                                                }
                                                setSelectedRows([]);
                                            }}
                                        >
                                            Approve Selected
                                        </PrimaryButton>
                                    )))
                            // : (
                            //     <PrimaryButton
                            //         onClick={async (e) => {
                            //             e.stopPropagation();
                            //             toast.error('Unauthorized');
                            //         }}
                            //     >
                            //         Approve Selected
                            //     </PrimaryButton>
                            // )
                        }
                        data={dataMemo}
                        columns={ShortlistColumns}
                        searchFunction={debouncedSearch}
                    />
                    <PaginationClassic
                        setPage={setReqPage}
                        paginationDetails={registrationRequest}
                    />
                </Tab>
            </Tabs>
        </PageWithCard>
    );
};

export default ApproveVolunteer;
