import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useNavigate } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PageCardForEvents from '../../components/infrastructure/PageCardForEvents';
import EventCard from '../../components/infrastructure/EventCard';
import { fetchEvents, getEvents } from '../../app/reducers/Event/eventSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import EventCustomButton from '../../components/infrastructure/Buttons/EventCustomButton';
import AssignCommitteeColor from './AssignCommitteeColor';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const Event = ({ actionFeatures }) => {
    const { committee, loading } = useSelector(getCommittees);
    const { features } = useSelector(getFeatures);
    const { user } = useSelector(getAuth);
    console.log('user event page', user);
    const { event } = useSelector(getEvents);
    console.log('events', event);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [canShowUpdate, setCanShowUpdate] = useState(true);
    const [requestData, setRequestData] = useState({});
    const [showColorModal, setShowColorModal] = useState(false);
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchCommittees({ search: search, populate: true }));
        }, 300),
        []
    );
    const actionsBtn = ['Update'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            Update: setCanShowUpdate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    const cols = [
        {
            Header: 'Committee',
            Cell: ({ row }) => {
                return (
                    <span
                        className="underline hover:text-blue-600 underline-offset-4 cursor-pointer"
                        onClick={async (e) => {
                            e.stopPropagation();
                            navigate(`/approveVolunteer/${row?.original?._id}`);
                        }}
                    >
                        {row.original.name}
                    </span>
                );
            },
        },

        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'No. of Events',
            accessor: 'numberOfEvents',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.numberOfEvents
                            ? row.original.numberOfEvents
                            : 'N.A'}
                    </span>
                );
            },
        },
        {
            Header: 'Members',
            accessor: 'members',
            Cell: ({ row }) => {
                const members = row.original.members || [];
                return (
                    <ul>
                        {members.map((member, index) => (
                            <li key={index}>
                                {member.role && <span>{member.role.name}</span>}
                                &nbsp; - &nbsp; {member.seats}
                            </li>
                        ))}
                    </ul>
                );
            },
        },

        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        <Edit2
                            onClick={async () => {
                                navigate(`/committee/edit/${row.original._id}`);
                            }}
                            color="blue"
                        />
                        <Trash
                            onClick={async () => {
                                await dispatch(
                                    deleteCommittee({ id: row.original._id })
                                );
                                dispatch(fetchCommittees({ populate: true }));
                            }}
                            color="red"
                        />
                    </div>
                );
            },
        },
    ];
    const data = useMemo(
        () => (committee?.docs ? committee.docs : []),
        [committee]
    );
    console.log('memo', data);
    useEffect(() => {
        dispatch(
            fetchCommittees({
                type: 'Event',
                populate: true,
                sort: { order: 1 },
            })
        );
        dispatch(fetchEvents({ populate: true }));
        computeActiveFeature();
    }, [page, features]);
    return (
        <>
            <PageCardForEvents heading="Events">
                <AssignCommitteeColor
                    requestData={requestData}
                    showColorModal={showColorModal}
                    setShowColorModal={setShowColorModal}
                />
                <EventCard data={data}>
                    {data?.map((ele) => (
                        <div
                            className="p-4 w-full sm:w-1/2 lg:w-1/3"
                            key={ele?._id}
                        >
                            <div
                                className="h-full bg-gray-300 bg-opacity-75 p-10 rounded-md sm:rounded-xl overflow-hidden text-center relative"
                                style={{
                                    backgroundColor: `${ele.hexCode}`,
                                }}
                            >
                                <h3 className="w-full mb-1 text-black font-semibold text-3xl">
                                    {ele?.name}
                                </h3>
                                <hr className="border-black border-1 mb-4" />
                                {[
                                    'HOD',
                                    'Head',
                                    'Sub Head',
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            navigate(
                                                `/committee/event/approveVolunteer/${ele?._id}`
                                            );
                                        }}
                                    >
                                        Committee
                                    </EventCustomButton>
                                )}
                                {[
                                    'HOD',
                                    'Head',
                                    'Sub Head',
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            const eventId = event?.docs?.filter(
                                                (item) =>
                                                    item?.committee?._id ===
                                                    ele?._id
                                            );
                                            console.log('eventID', eventId);
                                            eventId.sort((a, b) => {
                                                if (a.order < b.order)
                                                    return -1;
                                                if (a.order > b.order) return 1;
                                                return 0;
                                            });
                                            console.log(
                                                'eventID',
                                                eventId[0]?._id
                                            );
                                            if (eventId) {
                                                navigate(
                                                    `/committee/event/editEvent/${ele?._id}/${eventId[0]?._id}`
                                                );
                                            } else {
                                                navigate(`/event/add`);
                                            }
                                        }}
                                    >
                                        Event Setup
                                    </EventCustomButton>
                                )}
                                {[
                                    'CL',
                                    'Developer',
                                    'NCP Representative',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            const eventId = event?.docs?.filter(
                                                (item) =>
                                                    item?.committee?._id ===
                                                    ele?._id
                                            );
                                            console.log('eventID', eventId);
                                            eventId.sort((a, b) => {
                                                if (a.order < b.order)
                                                    return -1;
                                                if (a.order > b.order) return 1;
                                                return 0;
                                            });
                                            if (user?.role?.name === 'CL') {
                                                if (eventId) {
                                                    navigate(
                                                        `/committee/event/participant/${ele?._id}/${eventId[0]?._id}`
                                                    );
                                                } else {
                                                    navigate(
                                                        `/participant/add`
                                                    );
                                                }
                                            }
                                            if (
                                                user?.role?.name ===
                                                'NCP Representative'
                                            ) {
                                                if (eventId) {
                                                    navigate(
                                                        `/committee/event/ncpParticipant/${ele?._id}/${eventId[0]?._id}`
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        Registration
                                    </EventCustomButton>
                                )}
                                {[
                                    'HOD',
                                    'Head',
                                    'Sub Head',
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                           
                                            const eventData = [
                                                ...(event?.docs || []),
                                            ];

                                            const sortEvent = eventData.sort(
                                                (a, b) => a.order - b.order
                                            );
                                            
                                            const eventId = sortEvent?.find(
                                                (item) =>
                                                    item?.committee?._id ===
                                                    ele?._id
                                            );
                                            navigate(
                                                `/committee/event/viewParticipant/${ele?._id}/${eventId?._id}`
                                            );
                                        }}
                                    >
                                        Participants
                                    </EventCustomButton>
                                )}
                                {[
                                    'HOD',
                                    'Head',
                                    'Sub Head',
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            const eventId = event?.docs?.filter(
                                                (item) =>
                                                    item?.committee?._id ===
                                                    ele?._id
                                            );
                                            console.log('eventID', eventId);
                                            eventId.sort((a, b) => {
                                                if (a.order < b.order)
                                                    return -1;
                                                if (a.order > b.order) return 1;
                                                return 0;
                                            });
                                            console.log(
                                                'eventID',
                                                eventId[0]?._id
                                            );
                                            if (eventId) {
                                                navigate(
                                                    `/committee/event/scoreboard/${ele?._id}/${eventId[0]?._id}`
                                                );
                                            }
                                        }}
                                    >
                                        Scoreboard
                                    </EventCustomButton>
                                )}
                                {[
                                    'HOD',
                                    'Developer',
                                    'Super User',
                                    'Head',
                                ].includes(user?.role?.name) &&
                                    canShowUpdate && (
                                        <EventCustomButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                setRequestData(ele);
                                                setShowColorModal(true);
                                            }}
                                        >
                                            Assign Committee Color
                                        </EventCustomButton>
                                    )}
                            </div>
                        </div>
                    ))}
                    {user?.role?.name !== 'NCP Representative' && (
                        <div className="p-4 w-full sm:w-1/2 lg:w-1/3">
                            <div
                                className="h-full bg-gray-300 bg-opacity-75 p-10 rounded-md sm:rounded-xl overflow-hidden text-center relative"
                                // style={{ backgroundColor: '#FFFFFF' }}
                            >
                                <h3 className="w-full mb-1 text-black font-semibold text-3xl">
                                    War Of CL
                                </h3>
                                <hr className="border-black border-1 mb-4" />
                                {[
                                    'HOD',
                                    'Developer',
                                    'Super User',
                                    'Head',
                                    'Sub Head',
                                    'Admin Head',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate('/warOfClEventSetup');
                                        }}
                                    >
                                        Event Setup
                                    </EventCustomButton>
                                )}
                                {[
                                    'HOD',
                                    'Developer',
                                    'Super User',
                                    'Head',
                                    'Sub Head',
                                    'Admin Head',
                                    'Volunteer',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate('/warOfClParticipants');
                                        }}
                                    >
                                        Participants
                                    </EventCustomButton>
                                )}
                                {[
                                    'CL',
                                    'ACL',
                                    'Developer',
                                    // 'Super User',
                                ].includes(user?.role?.name) && (
                                    <EventCustomButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate('/warOfClRegistration');
                                        }}
                                    >
                                        Registration
                                    </EventCustomButton>
                                )}
                            </div>
                        </div>
                    )}
                </EventCard>
            </PageCardForEvents>
            {/* 
            <PageWithCard heading={'View committees'}>
                <TableWithHeadingAndGlobalSearch
                    loading={loading}
                    heading={'View committees'}
                    data={data}
                    searchFunction={debouncedSearch}
                    columns={cols}
                />
                <PaginationClassic
                    setPage={setPage}
                    paginationDetails={committee}
                />
            </PageWithCard> */}
        </>
    );
};

export default Event;
