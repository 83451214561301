import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../app/reducers/Auth/authSlice';
import { getWebsiteCreatives } from '../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { fetchEvents, getEvents } from '../app/reducers/Event/eventSlice';
import {
    fetchParticipants,
    getParticipants,
} from '../app/reducers/Participant/teamParticipantSlice';
import moment from 'moment/moment';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useMediaQuery } from 'react-responsive';
import O2Favicon from '../images/O2 favicon.png';
import { authAxiosInstance } from '../utils/axiosConfig';
import QueryString from 'qs';
import SetButtonsDashBoard from '../components/infrastructure/SetButtonsDashBoard';

const DashBoardSchedule = () => {
    const { user } = useSelector(getAuth);
    const { event } = useSelector(getEvents);
    const isMobile = useMediaQuery({ maxWidth: 1280 });
    const [eventGroup, setEventGroup] = useState({
        scheduled: {},
        unscheduled: [],
    });
    const [selectedDate, setSelectedDate] = useState(null);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];

    const [eventParticipant, setEventParticipant] = useState([]);
    const dispatch = useDispatch();
    const computeEventDate = () => {
        const eventDate = event?.docs.reduce(
            (acc, ele) => {
                if (ele.status === 'scheduled') {
                    const date = moment(ele.startTime).format('DD/MM/YYYY');
                    const time = moment(ele.startTime).format('hh:mm a');

                    if (!acc.scheduled[date]) {
                        acc.scheduled[date] = {};
                    }

                    if (acc.scheduled[date][time]) {
                        acc.scheduled[date][time] = [
                            ...acc.scheduled[date][time],
                            ele,
                        ];
                    } else {
                        acc.scheduled[date][time] = [ele];
                    }
                } else {
                    acc.unscheduled = [...acc.unscheduled, ele];
                }

                return acc;
            },
            { scheduled: {}, unscheduled: [] }
        );
        setEventGroup(eventDate);
    };
    const computeEventWiseParticipants = async () => {
        try {
            const stringData = QueryString.stringify({ populate: true, limit:5000 });
            const response = await authAxiosInstance.get(
                `/teamParticipant?${stringData}`
            );
            const teamPartiData = response?.data?.data?.docs;
            const data = {
                eventIds: teamPartiData?.map((ele) => ele.event?._id),
                status: 'approved',
                populate: true,
                limit: 5000,
            };
            const resp = await authAxiosInstance.post(
                `/teamParticipant/getEventParticipantData`,
                data
            );
            setEventParticipant(resp?.data?.data);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        dispatch(
            fetchEvents({
                populate: true,
                status: 'scheduled',
                sort: { startTime: 1 },
            })
        );
        dispatch(fetchParticipants({ populate: true, limit: 5000 }));
    }, []);

    useEffect(() => {
        if (event?.docs?.length) {
            computeEventDate();
        }
    }, [event]);
    useEffect(() => {
        computeEventWiseParticipants();
    }, []);

    useEffect(() => {
        const firstDate = Object.keys(eventGroup.scheduled)[0];
        if (firstDate) {
            setSelectedDate(firstDate);
        }
    }, [eventGroup]);

    const participantLength = (eventId) => {
        const data = eventParticipant?.find(
            (ele) => ele.eventData?._id === eventId
        );
        let count = data?.participants?.length;
        return count;
    };
    return (
        <section className="min-h-screen">
            <div className="container mx-auto flex flex-col item-center justify-center p-6 h-auto w-full lg:px-44 lg:py-8 ">
                <div className="text-center mx-auto">
                    <SetButtonsDashBoard>
                        {Object.keys(eventGroup.scheduled).map(
                            (date, index) => (
                                <div
                                    className="flex items-center justify-start divide-x divide-slate-600"
                                    key={date}
                                >
                                    <button
                                        key={index}
                                        className="btn px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px] "
                                        style={
                                            selectedDate === date
                                                ? {
                                                      color: dynamicDefaultData
                                                          ? dynamicDefaultData?.highlightedText
                                                          : '#DE3636',
                                                  }
                                                : {
                                                      color: '#ffffff',
                                                  }
                                        }
                                        onClick={() => setSelectedDate(date)}
                                    >
                                        {moment(date, 'DD/MM/YYYY').format(
                                            'Do MMM YYYY'
                                        )}
                                    </button>
                                </div>
                            )
                        )}
                    </SetButtonsDashBoard>
                </div>
                <VerticalTimeline>
                    {selectedDate &&
                        Object.keys(eventGroup.scheduled[selectedDate]).map(
                            (time) => (
                                <VerticalTimelineElement
                                    key={time}
                                    className="vertical-timeline-element--work"
                                    // contentStyle={{ background: "rgb(0,0,0,0.5)", color: "#fff" }}
                                    contentArrowStyle={{
                                        borderRight: dynamicDefaultData
                                            ? `7px solid ${dynamicDefaultData?.highlightedText}`
                                            : '7px solid rgb(33, 150, 243)',
                                    }}
                                    date={
                                        <span
                                            style={{
                                                color: dynamicDefaultData
                                                    ? dynamicDefaultData?.bubbleText
                                                    : '#ffffff',
                                            }}
                                        >
                                            {time}
                                        </span>
                                    }
                                    icon={
                                        <img
                                            src={
                                                dynamicDefaultData?.scheduleIcon
                                                    ? `${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.scheduleIcon}`
                                                    : O2Favicon
                                            }
                                            alt="O2 Favicon"
                                        />
                                    }
                                >
                                    {isMobile && (
                                        <div
                                            className="vertical-date mb-2"
                                            style={{
                                                backgroundColor:
                                                    dynamicDefaultData
                                                        ? dynamicDefaultData?.highlightedText
                                                        : '#DE3636',
                                                color: dynamicDefaultData
                                                    ? dynamicDefaultData?.bubbleText
                                                    : '#ffffff',
                                            }}
                                        >
                                            {time}
                                        </div>
                                    )}
                                    {eventGroup.scheduled[selectedDate][
                                        time
                                    ].map((event) => (
                                        <div
                                            className="pl-2 p-2 rounded-lg mb-2"
                                            style={{
                                                backgroundColor:
                                                    event?.runningStatus ===
                                                    'ended'
                                                        ? '#212121'
                                                        : event?.committee
                                                                ?.hexCode
                                                          ? event?.committee
                                                                ?.hexCode
                                                          : '#3b82f6',
                                            }}
                                        >
                                            <h3
                                                className="text-white font-medium md:font-bold text-2xl md:text-3xl"
                                                style={{
                                                    color:
                                                        event?.runningStatus ===
                                                        'ended'
                                                            ? '#6C6C6C'
                                                            : dynamicDefaultData
                                                              ? dynamicDefaultData?.bubbleText
                                                              : '#ffffff',
                                                }}
                                            >
                                                {event?.name}
                                            </h3>
                                            <p
                                                className="text-white customScheduleCard text-ellipsis overflow-hidden"
                                                style={{
                                                    color:
                                                        event?.runningStatus ===
                                                        'ended'
                                                            ? '#6C6C6C'
                                                            : dynamicDefaultData
                                                              ? dynamicDefaultData?.bubbleText
                                                              : '#ffffff',
                                                }}
                                            >
                                                {event?.subTitle}
                                            </p>
                                            {/* <p className="text-white">
                                                Start Time: {moment(event.startTime).format("hh:mm a")}
                                                </p> */}
                                            <p
                                                className="text-white"
                                                style={{
                                                    color:
                                                        event?.runningStatus ===
                                                        'ended'
                                                            ? '#6C6C6C'
                                                            : dynamicDefaultData
                                                              ? dynamicDefaultData?.bubbleText
                                                              : '#ffffff',
                                                }}
                                            >
                                                Venue: {event?.venue}
                                            </p>
                                            <p
                                                className="text-white customShceduleCard"
                                                style={{
                                                    color:
                                                        event?.runningStatus ===
                                                        'ended'
                                                            ? '#6C6C6C'
                                                            : dynamicDefaultData
                                                              ? dynamicDefaultData?.bubbleText
                                                              : '#ffffff',
                                                }}
                                            >
                                                End Time:{' '}
                                                {moment(event.endTime).format(
                                                    'hh:mm a'
                                                )}
                                            </p>
                                            {event.runningStatus ===
                                                'upcoming' && (
                                                <p
                                                    className="text-white customShceduleCard"
                                                    style={{
                                                        color: dynamicDefaultData
                                                            ? dynamicDefaultData?.bubbleText
                                                            : '#ffffff',
                                                    }}
                                                >
                                                    Status:{' '}
                                                    {event?.runningStatus}
                                                </p>
                                            )}
                                            {event.runningStatus ===
                                                'ongoing' && (
                                                <p
                                                    className="text-white customShceduleCard"
                                                    style={{
                                                        color: dynamicDefaultData
                                                            ? dynamicDefaultData?.bubbleText
                                                            : '#ffffff',
                                                    }}
                                                >
                                                    Status:{' '}
                                                    <span className="bg-green-500 px-1">
                                                        {event?.runningStatus}
                                                    </span>
                                                </p>
                                            )}
                                            {event.runningStatus ===
                                                'ended' && (
                                                <p
                                                    className="text-white customShceduleCard"
                                                    style={{
                                                        color:
                                                            event?.runningStatus ===
                                                            'ended'
                                                                ? '#6C6C6C'
                                                                : dynamicDefaultData
                                                                  ? dynamicDefaultData?.bubbleText
                                                                  : '#ffffff',
                                                    }}
                                                >
                                                    Status:{' '}
                                                    <span className="bg-red-500 px-1 text-white">
                                                        {event?.runningStatus}
                                                    </span>
                                                </p>
                                            )}
                                            <p
                                                className="text-white customShceduleCard"
                                                style={{
                                                    color: dynamicDefaultData
                                                        ? dynamicDefaultData?.bubbleText
                                                        : '#ffffff',
                                                }}
                                            >
                                                Registrations:{' '}
                                                {participantLength(event?._id) ? participantLength(event?._id) : 0}
                                            </p>
                                        </div>
                                    ))}
                                </VerticalTimelineElement>
                            )
                        )}
                </VerticalTimeline>
            </div>
        </section>
    );
};

export default DashBoardSchedule;
