import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import FormikLabel from '../../components/formik/FormikLabel';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteCreativeData,
    fetchWebsiteCreativesData,
    getWebsiteCreatives,
} from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { XSquare } from 'react-feather';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const Sponsors = ({ canShowOtherUpdate, pages }) => {
    const { user } = useSelector(getAuth);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const elementEditData = websiteCreatives[0];
    console.log('edit data', elementEditData);
    const dispatch = useDispatch();

    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                name: pages,
                titleSponsor: '',
                titleSponsorLogo: '',
                titleSponsorLink: '',
                associateSponsors: [
                    {
                        title: '',
                        logo: '',
                        link: '',
                    },
                ],
                pastSponsors: [
                    {
                        title: '',
                        logo: '',
                        link: '',
                    },
                ],
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        onSubmit: (values) => {
            console.log('sponsors values', values);
            const obj = {
                ...values,
                name: pages,
            };
            console.log('object', obj);
            dispatch(createWebsiteCreativeData(obj));
        },
    });

    useEffect(() => {
        dispatch(fetchWebsiteCreativesData({ name: 'Sponsors' }));
    }, []);
    return (
        <>
            <div className="mb-2">
                <ShowDropdownForSchedule
                    heading={
                        <div className="mb-3">
                            <h1 className="text-slate-200 text-xl font-medium px-2">
                                Title Sponsor
                            </h1>
                        </div>
                    }
                    allowedRoles={[
                        'Super User',
                        'HOD',
                        'Admin Head',
                        'Developer',
                    ]}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-2"
                    >
                        {/* <div className='flex flex-col gap-2'> */}
                        <div className="w-full flex flex-row items-center justify-between">
                            <div className="w-[30%]">
                                <FormikLabel label="Image" />
                            </div>
                            <div className="w-[70%]">
                                <FormikDirectImageUpload
                                    formik={formik}
                                    name="titleSponsorLogo"
                                    location={`1648_entertainment/websitesCreatives/sponsors/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row items-center justify-between">
                            <div className="w-[30%]">
                                <FormikLabel label="Title" />
                            </div>
                            <div className="w-[70%]">
                                <FormikInputGroup
                                    formik={formik}
                                    name="titleSponsor"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row items-center justify-between">
                            <div className="w-[30%]">
                                <FormikLabel label="URL" />
                            </div>
                            <div className="w-[70%]">
                                <FormikInputGroup
                                    formik={formik}
                                    name="titleSponsorLink"
                                />
                            </div>
                        </div>
                        {[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ].includes(user?.role?.name) &&
                            canShowOtherUpdate && (
                                <div className="text-center ">
                                    <DangerButton type="submit">
                                        Update
                                    </DangerButton>
                                </div>
                            )}
                        {/* </div> */}
                    </form>
                </ShowDropdownForSchedule>
            </div>

            {
                <div className="mb-2">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-3">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Our Partners
                                </h1>
                            </div>
                        }
                        allowedRoles={[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ]}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div>
                                <FormikProvider
                                    value={formik}
                                    name={'associateSponsors'}
                                >
                                    <FieldArray
                                        name="associateSponsors"
                                        render={(arrayHelpers) => {
                                            return (
                                                <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                    {formik?.values?.associateSponsors?.map(
                                                        (ele, index) => (
                                                            <div
                                                                className="mb-2"
                                                                key={ele._id}
                                                            >
                                                                <div className="relative">
                                                                    <XSquare
                                                                        className=" right-0 text-red-500"
                                                                        onClick={() =>
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="flex flex-col gap-2 text-slate-200">
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Image" />
                                                                            <div className="w-[70%]">
                                                                                <FormikDirectImageUpload
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`associateSponsors.${index}.logo`}
                                                                                    location={`1648_entertainment/websitesCreatives/sponsors/`}
                                                                                    randomize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Title" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`associateSponsors.${index}.title`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="URL" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`associateSponsors.${index}.link`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                    <div>
                                                        <PrimaryButton
                                                            type="button"
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    {}
                                                                );
                                                            }}
                                                        >
                                                            Add more
                                                        </PrimaryButton>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </FormikProvider>
                            </div>
                            {[
                                'Super User',
                                'HOD',
                                'Admin Head',
                                'Developer',
                            ].includes(user?.role?.name) &&
                                canShowOtherUpdate && (
                                    <div className="text-center ">
                                        <DangerButton type="submit">
                                            Update
                                        </DangerButton>
                                    </div>
                                )}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
            {
                <div className="mb-2">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-3">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Past Sponsors
                                </h1>
                            </div>
                        }
                        allowedRoles={[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ]}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div>
                                <FormikProvider
                                    value={formik}
                                    name={'pastSponsors'}
                                >
                                    <FieldArray
                                        name="pastSponsors"
                                        render={(arrayHelpers) => {
                                            return (
                                                <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                    {formik?.values?.pastSponsors?.map(
                                                        (ele, index) => (
                                                            <div
                                                                className="mb-2"
                                                                key={index}
                                                            >
                                                                <div className="relative">
                                                                    <XSquare
                                                                        className=" right-0 text-red-500"
                                                                        onClick={() =>
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="flex flex-col gap-2 text-slate-200">
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Image" />
                                                                            <div className="w-[70%]">
                                                                                <FormikDirectImageUpload
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`pastSponsors.${index}.logo`}
                                                                                    location={`1648_entertainment/websitesCreatives/sponsors/`}
                                                                                    randomize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Title" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`pastSponsors.${index}.title`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="URL" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`pastSponsors.${index}.link`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                    <div>
                                                        <PrimaryButton
                                                            type="button"
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    {}
                                                                );
                                                            }}
                                                        >
                                                            Add more
                                                        </PrimaryButton>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </FormikProvider>
                            </div>
                            {[
                                'Super User',
                                'HOD',
                                'Admin Head',
                                'Developer',
                            ].includes(user?.role?.name) &&
                                canShowOtherUpdate && (
                                    <div className="text-center ">
                                        <DangerButton type="submit">
                                            Update
                                        </DangerButton>
                                    </div>
                                )}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
        </>
    );
};

export default Sponsors;
